import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Center,
  ExpandedIndex,
  Flex,
  Heading,
  Icon,
  Spacer,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import { FaLink } from "react-icons/fa";
import FavouriteButton from "../../components/FavouriteButton";
import Layout from "../../components/Layout";
import { Resource, useModules } from "../../data/modulesAPI";
import SearchComponent from "../../components/SearchComponent";
import InstructionText from "../../components/InstructionText";
import QueryCacheProvider from "../../context/query-cache-context";
import { useToolbox } from "../../data/toolboxApi";

type Props = {};

const Resources = (props: Props) => {
  const [index, setIndex] = useState<ExpandedIndex>();
  const [selectedResource, setSelectedResource] = useState<Resource>();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const modules = useModules();

  const bg = useColorModeValue("white", "gray.800");
  const blue = useColorModeValue("blue.100", "gray.800");
  const grey = useColorModeValue("gray.100", "gray.800");
  const greyHover = useColorModeValue("gray.200", "gray.700");
  const hover = useColorModeValue("blue.100", "gray.700");
  const buttonColor = useColorModeValue("blue.800", "blue.400");

  const moduleBg = useColorModeValue("white", "grey.800");
  const moduleBorder = useColorModeValue("grey.300", "grey.300");

  const gotoResource = (resource: Resource) => {
    window.open(resource.url, "_blank");
  };

  // const { data, isLoading, refetch } = useToolbox();
  const {
    data: toolBoxData,
    loading: toolBoxLoading,
    fetchData: toolBoxRefetch,
  } = useToolbox();

  useEffect(() => {
    toolBoxRefetch();
  }, []);

  useEffect(() => {
    document.title = "Resources";
  }, []);

  return (
    <main aria-label="Resources">
      <Layout>
        <Flex>
          <Center w="full">
            <Heading as="h1">Resources</Heading>
            <Spacer />
            <InstructionText
              title={"About Resources"}
              mdId={"instruction-text/resources"}
            />
            {isMobile ? <SearchComponent asInput={false} dark={false} /> : null}
          </Center>
        </Flex>
        <Flex w="full" gap={10}>
          <Box minW={0} flex={1} rounded="lg" bg={bg} boxShadow="lg" p={8}>
            {/* <Flex>
              <Heading mb={3}>Resources</Heading>
              <Spacer />
              {isMobile ? <SearchComponent asInput={false} dark={true} /> : null}
            </Flex> */}
            <Text mb={8}>Select a category to see the resources</Text>

            <QueryCacheProvider>
              <Accordion index={index} onChange={setIndex} allowMultiple>
                {modules.map((module, index) => (
                  <AccordionItem
                    border={0}
                    mb={4}
                    isDisabled={module.resources.length === 0}
                  >
                    <h2>
                      <AccordionButton
                        bg={moduleBg}
                        borderColor={"grey.300"}
                        borderWidth={"1.5px"}
                        _hover={{ bg: hover, borderColor: hover }}
                        rounded="md"
                        p={4}
                      >
                        <Box flex="1" textAlign="left">
                          <Text fontWeight="bold">{module.title}</Text>
                          <Badge
                            mt={2}
                            pl={"10px"}
                            pr={"10px"}
                            py={"4px"}
                            colorScheme={"green"}
                            rounded={"lg"}
                            fontSize="sm"
                          >
                            {module.resources.length} resources
                          </Badge>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} px={0}>
                      <Stack>
                        <Box
                          w="full"
                          bg={moduleBg}
                          borderColor={moduleBorder}
                          borderWidth={"1.5px"}
                          rounded="md"
                          p={4}
                        >
                          {module.resources.map((resource, i) => (
                            <Flex
                              bg={moduleBg}
                              gap={2}
                              align="center"
                              rounded="md"
                              p={3}
                              pr={4}
                              onClick={() => gotoResource(resource)}
                              style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                              }}
                              _hover={{
                                cursor: "pointer",
                                bg: hover,
                              }}
                              my={1}
                            >
                              <Icon as={FaLink} mr={4} ml={4} />
                              <Text flex={1}>{resource.title}</Text>
                              <FavouriteButton
                                favouriteType="resource"
                                id={resource.id}
                                moreData={{
                                  name: resource.title,
                                  description: resource.description,
                                  url: resource.url,
                                }}
                                // toolBoxData={data ?? null}
                                // refetchToolbox={refetch}
                                toolBoxLoading={toolBoxLoading}
                                toolBoxData={toolBoxData}
                                refetchToolbox={toolBoxRefetch}
                              />
                            </Flex>
                          ))}
                        </Box>
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </QueryCacheProvider>
          </Box>
        </Flex>
      </Layout>
    </main>
  );
};

export default Resources;
